<template>
  <div>
    <v-dialog v-model="dialog" persistent :max-width="width">
      <v-card :max-width="width">
        <v-card-title class="primary" style="padding-top: 10px">
          <span class="white--text">คู่มือการใช้งาน</span>
          <v-btn
            icon
            class="ml-auto white--text"
            style="margin-right: -8px"
            @click="cancel"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-progress-linear v-if="isLoading" indeterminate></v-progress-linear>
        <v-card-text class="pt-3" style="font-size: 16px">
          <v-list>
            <template v-for="(document, indexdocument) in documentList">
              <v-card
                :class="{ 'mt-2': indexdocument > 0 }"
                :key="indexdocument"
                outlined
              >
                <v-list-item
                  :key="indexdocument"
                  link
                  :href="document.url"
                  target="_blank"
                  two-line
                >
                  <v-list-item-avatar>
                    <v-icon>mdi-file-document</v-icon>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title class="text-wrap">
                      {{ document.description }}
                    </v-list-item-title>
                    <v-list-item-subtitle></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
          </v-list>
        </v-card-text>

        <v-divider></v-divider>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false
    },
    cancelButtonText: {
      type: String,
      default: 'ตกลง'
    }
  },
  data () {
    return {
      dialog: false,
      documentList: [],
      isLoading: false,
      width: 500
    }
  },
  watch: {
    async value (newVal, oldVal) {
      this.dialog = newVal
      if (newVal) {
        this.getDocumentDownloadList()
      }
    }
  },
  methods: {
    cancel () {
      this.dialog = false
      this.$emit('cancel')
    },
    async getDocumentDownloadList () {
      this.documentList = []
      this.isLoading = true
      this.documentList = await this.$store.dispatch('getDocumentDownloadList')
      this.isLoading = false
    }
  }
}
</script>
